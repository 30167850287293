import React, { useState } from 'react';
import Modal from 'react-modal';
import './App.css';

const mapsPool = ["Dust2", "Inferno", "Vertigo", "Nuke", "Ancient", "Anubis", "Mirage"];

Modal.setAppElement('#root'); // For accessibility

const App = () => {
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");
  const [vetoOrder, setVetoOrder] = useState([]);
  const [maps, setMaps] = useState(mapsPool);
  const [vetoStarted, setVetoStarted] = useState(false);
  const [isSideModalOpen, setIsSideModalOpen] = useState(false);
  const [currentMapPick, setCurrentMapPick] = useState(null);
  const [currentPickingTeam, setCurrentPickingTeam] = useState("");
  const [sidePickingTeam, setSidePickingTeam] = useState("");

  const handleSwapTeams = () => {
    setTeam1(team2);
    setTeam2(team1);
  };

  const handleReset = () => {
    setTeam1("");
    setTeam2("");
    setVetoOrder([]);
    setMaps(mapsPool);
    setVetoStarted(false);
    setIsSideModalOpen(false);
  };

  const handleStartVeto = () => {
    if (!team1 || !team2) {
      alert("Please enter both team names");
      return;
    }
    setVetoOrder([]);
    setMaps(mapsPool);
    setVetoStarted(true);
  };

  const handleVeto = (map) => {
    const vetoOrderStages = ['ban', 'ban', 'pick', 'pick', 'ban', 'ban'];
    const currentTurnIndex = vetoOrder.length;
    const currentTeam = currentTurnIndex % 2 === 0 ? team1 : team2;

    if (vetoOrderStages[currentTurnIndex] === 'pick') {
      setCurrentMapPick(map);
      setCurrentPickingTeam(currentTeam);
      setSidePickingTeam(currentTeam === team1 ? team2 : team1);
      setIsSideModalOpen(true);
    } else {
      const updatedVetoOrder = [...vetoOrder, { map, team: currentTeam, action: vetoOrderStages[currentTurnIndex] }];
      setVetoOrder(updatedVetoOrder);
      if (updatedVetoOrder.length === vetoOrderStages.length) {
        const remainingMap = maps.filter(m => !updatedVetoOrder.some(v => v.map === m))[0];
        setVetoOrder([...updatedVetoOrder, { map: remainingMap, action: 'decider' }]);
      }
    }
  };

  const handleSidePick = (side) => {
    setVetoOrder([...vetoOrder, { map: currentMapPick, team: currentPickingTeam, action: 'pick', side, sideTeam: sidePickingTeam }]);
    setIsSideModalOpen(false);
    setCurrentMapPick(null);
  };

  const getCurrentTeam = () => {
    const vetoOrderStages = ['ban', 'ban', 'pick', 'pick', 'ban', 'ban', 'decider'];
    const turnOrder = [
      team1, team2, team1, team2, team1, team2
    ];
    if (vetoOrder.length >= vetoOrderStages.length - 1) {
      return "decider";
    }
    return turnOrder[vetoOrder.length];
  };

  const formatVetoMessage = (item) => {
    if (item.action === 'ban') {
      return `${item.team} has banned ${item.map}`;
    } else if (item.action === 'pick') {
      return `${item.team} has picked ${item.map} (${item.sideTeam} pick ${item.side} Side)`;
    } else {
      return `${item.map} is the decider`;
    }
  };

  return (
    <div className="App">
      <h1>CS2 Veto System</h1>
      <div className="teams-input">
        <input
          type="text"
          placeholder="Team 1"
          value={team1}
          onChange={(e) => setTeam1(e.target.value)}
        />
        <input
          type="text"
          placeholder="Team 2"
          value={team2}
          onChange={(e) => setTeam2(e.target.value)}
        />
      </div>
      <div className="buttons">
        <button onClick={handleSwapTeams}>Swap Teams</button>
        <button onClick={handleReset}>Reset</button>
        <button onClick={handleStartVeto}>Start Veto</button>
      </div>
      {vetoStarted && (
        <div className="veto">
          {vetoOrder.length < 6 && <h2>Current Turn: {getCurrentTeam()}</h2>}
          <div className="maps">
            {mapsPool.map((map) => {
              const veto = vetoOrder.find(v => v.map === map);
              return (
                <div
                  key={map}
                  className={`map-button ${veto ? veto.action : ''}`}
                  style={{ backgroundImage: `url(/assets/${map}.jpg)` }}
                  onClick={() => !veto && handleVeto(map)}
                >
                  <div className="map-meta">
                    <span className="map-name">{map}</span>
                    {veto && (
                      <span className="team-name">
                        {veto.action === 'decider' ? 'Decider map' : veto.team}
                      </span>
                    )}
                  </div>
                
                </div>
              );
            })}
          </div>
          <div className="veto-order">
            <h3>Veto Order:</h3>
            <ul>
              {vetoOrder.map((item, index) => (
                <li key={index} className={item.action}>
                  {formatVetoMessage(item)}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <Modal
        isOpen={isSideModalOpen}
        onRequestClose={() => setIsSideModalOpen(false)}
        contentLabel="Side Pick Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>{sidePickingTeam}, pick your side for {currentMapPick}</h2>
        <div className="side-buttons">
          <button onClick={() => handleSidePick('CT')}>CT</button>
          <button onClick={() => handleSidePick('T')}>T</button>
        </div>
      </Modal>
    </div>
  );
};

export default App;
